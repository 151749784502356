/**
 * Copyright Schrodinger, LLC
 */
import Logger from 'bb/debug/Logger';

goog.require('goog.array');
goog.require('goog.string');

const environment = {};

/**
 * Keys into the environmentConfig object
 *
 * Note that for flags that affect the visibility of features within the app, consider using ProfileModel to provide
 * flags to those features rather than using the environment property directly. This is especially useful if whether
 * the feature is enabled is dependent on some other application state also, or the flag must be checked in many places
 * in the LD codebase.
 *
 * @enum {string}
 * @export
 */
environment.SettingKey = {
  ADV_QUERY_COLUMN_PICKER_WIDTH: 'ADV_QUERY_COLUMN_PICKER_WIDTH',
  ASSAY_COLUMNS_EXPANDED_VIEW: 'ASSAY_COLUMNS_EXPANDED_VIEW',
  ASSAY_DATE_VALUE_DISPLAY_FORMAT: 'ASSAY_DATE_VALUE_DISPLAY_FORMAT',
  ASSAY_DATETIME_VALUE_DISPLAY_FORMAT: 'ASSAY_DATETIME_VALUE_DISPLAY_FORMAT',
  COLUMN_GROUPING: 'COLUMN_GROUPING',
  COLUMN_TREE_SEARCH_LENGTH: 'COLUMN_TREE_SEARCH_LENGTH',
  COMPOUND_VOTE_OPTIONS: 'COMPOUND_VOTE_OPTIONS',
  COPY_LIVE_REPORT_ROLE: 'COPY_LIVE_REPORT_ROLE',
  CREATE_NEW_LIVE_REPORTS_AS_PRIVATE: 'CREATE_NEW_LIVE_REPORTS_AS_PRIVATE',
  CREATE_NEW_LIVE_REPORTS_AS_READ_ONLY: 'CREATE_NEW_LIVE_REPORTS_AS_READ_ONLY',
  CSRF_TOKEN: 'CSRF_TOKEN',
  CUSTOM_TOOLS_DEPRECATED_FORMAT: 'CUSTOM_TOOLS_DEPRECATED_FORMAT',
  CUSTOM_TOOLS: 'CUSTOM_TOOLS',
  UPDATED_TOOLS_LOCATION: 'UPDATED_TOOLS_LOCATION',
  DEFAULT_LIVE_REPORT_COLUMN_HEADER_HEIGHT: 'DEFAULT_LIVE_REPORT_COLUMN_HEADER_HEIGHT',
  DEFAULT_STRUCTURE_SEARCH_MAX_RESULTS: 'DEFAULT_STRUCTURE_SEARCH_MAX_RESULTS',
  DISABLE_PASSWORD_AUTOCOMPLETE: 'DISABLE_PASSWORD_AUTOCOMPLETE',
  DISPLAY_FEEDBACK_BUTTON: 'DISPLAY_FEEDBACK_BUTTON',
  DISPLAY_REFRESH_RESULTS_BUTTON: 'DISPLAY_REFRESH_RESULTS_BUTTON',
  ENABLE_ANNOTATION_WIDGET: 'ENABLE_ANNOTATION_WIDGET',
  ENABLE_AUDIT_LOG_PANEL: 'ENABLE_AUDIT_LOG_PANEL',
  ENABLE_CELL_AGGREGATION_TYPE: 'ENABLE_CELL_AGGREGATION_TYPE',
  ENABLE_COMPLEX_FILTERS: 'ENABLE_COMPLEX_FILTERS',
  ENABLE_COMPLEX_QUERY: 'ENABLE_COMPLEX_QUERY',
  ENABLE_DOCKED_TOOLTIPS: 'ENABLE_DOCKED_TOOLTIPS',
  ENABLE_ENUMERATION: 'ENABLE_ENUMERATION',
  ENABLE_FORMS_VIEW: 'ENABLE_FORMS_VIEW',
  ENABLE_FORMS_BATCH_DRILLDOWN: 'ENABLE_FORMS_BATCH_DRILLDOWN',
  ENABLE_GRID_COPY: 'ENABLE_GRID_COPY',
  ENABLE_INTERNAL_SCAFFOLD_ENUMERATION: 'ENABLE_INTERNAL_SCAFFOLD_ENUMERATION',
  ENABLE_LOG_FORWARDING: 'ENABLE_LOG_FORWARDING',
  ENABLE_MANUAL_MODEL_RERUNNING: 'ENABLE_MANUAL_MODEL_RERUNNING',
  ENABLE_SAR_COLUMN_SHAPE_RULES: 'ENABLE_SAR_COLUMN_SHAPE_RULES',
  DISTINGUISH_UNPUBLISHED_COLUMNS: 'DISTINGUISH_UNPUBLISHED_COLUMNS',
  ENABLE_REACT_COLUMN_MANAGEMENT: 'ENABLE_REACT_COLUMN_MANAGEMENT',
  ENABLE_SKETCHER_STEREO_LABELS: 'ENABLE_SKETCHER_STEREO_LABELS',
  USE_COLUMN_TREE_MICROSERVICE: 'USE_COLUMN_TREE_MICROSERVICE',
  ENABLE_LANDING_PAGE_V1: 'ENABLE_LANDING_PAGE_V1',
  ENTITY_ID_PREFIX: 'ENTITY_ID_PREFIX',
  ERROR_SUPPRESSION_LEVEL: 'ERROR_SUPPRESSION_LEVEL',
  FE_LOG_FORWARD_PERIOD: 'FE_LOG_FORWARD_PERIOD',
  FILE_UPLOAD_SIZE_LIMIT: 'FILE_UPLOAD_SIZE_LIMIT',
  FORCE_SVG_DOWNLOAD: 'FORCE_SVG_DOWNLOAD',
  GRID_COPY_ROW_LIMIT: 'GRID_COPY_ROW_LIMIT',
  HIDE_COMPOUND_STRUCTURE_COLUMN: 'HIDE_COMPOUND_STRUCTURE_COLUMN',
  HIDE_GLOBAL_PROJECT: 'HIDE_GLOBAL_PROJECT',
  HTML_ALLOWLIST: 'HTML_ALLOWLIST',
  IN_APP_SUBSCRIPTIONS_ENABLED: 'IN_APP_SUBSCRIPTIONS_ENABLED',
  INSTALL_SOURCEMAPS: 'INSTALL_SOURCEMAPS',
  LANDING_PAGE_FEEDBACK_LIVE_REPORTS: 'LANDING_PAGE_FEEDBACK_LIVE_REPORTS',
  LOG_FORWARDING_LEVEL: 'LOG_FORWARDING_LEVEL',
  LOGIN_DISCLAIMER_FOOTER: 'LOGIN_DISCLAIMER_FOOTER',
  LOGOUT_IDLE_USER_TIMEOUT: 'LOGOUT_IDLE_USER_TIMEOUT',
  MAX_ADVANCED_SEARCH_RETURN: 'MAX_ADVANCED_SEARCH_RETURN',
  MAX_LOADED_STRUCTURE_GROUPS: 'MAX_LOADED_STRUCTURE_GROUPS',
  STRUCTURE_SEARCH_MAX_COMPOUNDS: 'STRUCTURE_SEARCH_MAX_COMPOUNDS',
  MAX_ENUMERATION_RESULTS: 'MAX_ENUMERATION_RESULTS',
  PAGING_TREE_SEARCH_LENGTH: 'PAGING_TREE_SEARCH_LENGTH',
  PRODUCTION_ENVIRONMENT: 'PRODUCTION_ENVIRONMENT',
  PROJECT_PICKER_AREA_TITLE: 'PROJECT_PICKER_AREA_TITLE',
  PREVIEW_PROPERTIES_BY_DEFAULT: 'PREVIEW_PROPERTIES_BY_DEFAULT',
  PROTEIN_FILE_TRUNCATION: 'PROTEIN_FILE_TRUNCATION',
  WEBPYMOL_EXPORT_TRUNCATION: 'WEBPYMOL_EXPORT_TRUNCATION',
  REALTIME_3D: 'REALTIME_3D',
  SCALE_IMPORTED_DRC_IMAGES: 'SCALE_IMPORTED_DRC_IMAGES',
  SERVER_BASE_URL: 'SERVER_BASE_URL',
  SHUTDOWN_SKETCHER_TIMEOUT: 'SHUTDOWN_SKETCHER_TIMEOUT',
  SS_11303_LIVE_REPORTS_FOR_ENUMERATION: 'SS_11303_LIVE_REPORTS_FOR_ENUMERATION',
  THREE_D_STYLE: 'THREE_D_STYLE',
  ENABLE_STRICT_MATCH: 'ENABLE_STRICT_MATCH',
  LIMITED_ASSAY_COLUMNS_ENABLED: 'LIMITED_ASSAY_COLUMNS_ENABLED',
  AGGREGATION_COLUMNS: 'AGGREGATION_COLUMNS',
  LIVEDESIGN_MODE: 'LIVEDESIGN_MODE',
  LIVEDESIGN_3D_ICON: 'LIVEDESIGN_3D_ICON',
  ENABLE_PASSWORD_RESET: 'ENABLE_PASSWORD_RESET',
  DISABLE_SPACE_DELIMITER_IN_COMPOUND_ID_SEARCH: 'DISABLE_SPACE_DELIMITER_IN_COMPOUND_ID_SEARCH',
  DISPLAY_CUSTOM_HELP_BUTTON: 'DISPLAY_CUSTOM_HELP_BUTTON',
  CUSTOM_HELP_BUTTON_LABEL: 'CUSTOM_HELP_BUTTON_LABEL',
  CUSTOM_HELP_LINK: 'CUSTOM_HELP_LINK',
  ENABLE_HELP_LINK_TO_TUTORIAL_ENDPOINT: 'ENABLE_HELP_LINK_TO_TUTORIAL_ENDPOINT',
  ENABLE_PROJECT_COLUMN_ALIAS: 'ENABLE_PROJECT_COLUMN_ALIAS',
  ENABLE_LIVE_REPORT_COLUMN_ALIAS: 'ENABLE_LIVE_REPORT_COLUMN_ALIAS',
  VIZ_WIDGET_ENABLED: 'VIZ_WIDGET_ENABLED',
  ENABLE_STEREOISOMER_COMPOUND_LINK: 'ENABLE_STEREOISOMER_COMPOUND_LINK',
  OLED_IMPORT_TOOL_NAME: 'OLED_IMPORT_TOOL_NAME',
  HEATMAP_PLOT_ENABLED: 'HEATMAP_PLOT_ENABLED',
  ENABLE_ENHANCEMENT_FOR_SS_25882_RESIZABLE_FILTER_PANE:
    'ENABLE_ENHANCEMENT_FOR_SS_25882_RESIZABLE_FILTER_PANE',
  ENABLE_ONLY_LIVE_REPORTS_AS_SOURCE_FOR_REACTANTS:
    'ENABLE_ONLY_LIVE_REPORTS_AS_SOURCE_FOR_REACTANTS',
  ENABLE_LIVE_REPORTS_AS_SOURCE_FOR_REACTANTS: 'ENABLE_LIVE_REPORTS_AS_SOURCE_FOR_REACTANTS',
  CHART_SELECTION_UPDATER_ENABLED: 'CHART_SELECTION_UPDATER_ENABLED',
  ENABLE_WEIGHTED_MPOS: 'ENABLE_WEIGHTED_MPOS',
  ENABLE_COLUMN_TREE_MULTI_SELECT: 'ENABLE_COLUMN_TREE_MULTI_SELECT',
  ADD_COLUMN_LIVEREPORT_MULTISELECT_MAX_COUNT: 'ADD_COLUMN_LIVEREPORT_MULTISELECT_MAX_COUNT',
  ADD_COLUMN_ADVANCED_SEARCH_MULTISELECT_MAX_COUNT:
    'ADD_COLUMN_ADVANCED_SEARCH_MULTISELECT_MAX_COUNT',
  FORMULA_FUNCTION_BLOCKLIST: 'FORMULA_FUNCTION_BLOCKLIST',
  SERVER_TIMEZONE: 'SERVER_TIMEZONE',
  ENABLE_FORMS_CUSTOM_TOOL: 'ENABLE_FORMS_CUSTOM_TOOL',
  ENABLE_ENUMERATION_FILE_UPLOADED_METADATA: 'ENABLE_ENUMERATION_FILE_UPLOADED_METADATA',
  ENABLE_COMPOUNDS_PANE_REACT: 'ENABLE_COMPOUNDS_PANE_REACT',
  ENABLE_GRID_KEYBOARD_NAV: 'ENABLE_GRID_KEYBOARD_NAV',
  ENABLE_AUTOPOPULATING_COLOR_RULES: 'ENABLE_AUTOPOPULATING_COLOR_RULES',
  ENABLE_SAR_COLUMN_COLOR_RULES: 'ENABLE_SAR_COLUMN_COLOR_RULES',
  ENABLE_REACT_ERROR_WINDOW: 'ENABLE_REACT_ERROR_WINDOW',
  ENABLE_FIX_FOR_SS_29768_MULTIPLE_LRS_PLOTS_DUPLICATE_DATA:
    'ENABLE_FIX_FOR_SS_29768_MULTIPLE_LRS_PLOTS_DUPLICATE_DATA',
  ENABLE_DEVICE_LIVE_REPORTS: 'ENABLE_DEVICE_LIVE_REPORTS',
  ENABLE_SHOW_STEREOCENTERS_BY_DEFAULT: 'ENABLE_SHOW_STEREOCENTERS_BY_DEFAULT',
  ENABLE_GENERIC_ENTITY: 'ENABLE_GENERIC_ENTITY',
  ENABLE_GENERIC_ENTITY_LOT: 'ENABLE_GENERIC_ENTITY_LOT',
  ENABLE_FIX_FOR_SS_32557_FIX_EMPTY_ATTACHMENT_VALUES:
    'ENABLE_FIX_FOR_SS_32557_FIX_EMPTY_ATTACHMENT_VALUES',
  BINDING_SITE_STICK_RADIUS: 'BINDING_SITE_STICK_RADIUS',
  RESIDUE_LABEL_SIZE: 'RESIDUE_LABEL_SIZE',
  RESIDUE_LABEL_COLOR_BLACK_BACKGROUND: 'RESIDUE_LABEL_COLOR_BLACK_BACKGROUND',
  RESIDUE_LABEL_COLOR_WHITE_BACKGROUND: 'RESIDUE_LABEL_COLOR_WHITE_BACKGROUND',
  ENABLE_WEBPYMOL_FAST_DIFFERENCE: 'ENABLE_WEBPYMOL_FAST_DIFFERENCE',
  ENABLE_WEBPYMOL_GRID_COLUMN_ORDER: 'ENABLE_WEBPYMOL_GRID_COLUMN_ORDER',
  SCATTER_PLOT_MULTI_VALUES_ENABLED: 'SCATTER_PLOT_MULTI_VALUES_ENABLED',
  BOX_PLOT_MULTI_VALUES_ENABLED: 'BOX_PLOT_MULTI_VALUES_ENABLED',
  HISTOGRAM_PLOT_MULTI_VALUES_ENABLED: 'HISTOGRAM_PLOT_MULTI_VALUES_ENABLED',
  PIE_PLOT_MULTI_VALUES_ENABLED: 'PIE_PLOT_MULTI_VALUES_ENABLED',
  RADAR_PLOT_MULTI_VALUES_ENABLED: 'RADAR_PLOT_MULTI_VALUES_ENABLED',
  ENABLE_FIX_FOR_SS_34057_DRAG_N_DROP_BROKEN: 'ENABLE_FIX_FOR_SS_34057_DRAG_N_DROP_BROKEN',
  ENABLED_LIGAND_DESIGNER_WORKFLOWS: 'ENABLED_LIGAND_DESIGNER_WORKFLOWS',
  SHOW_ASSAY_METADATA_IN_CELL_TOOLTIPS: 'SHOW_ASSAY_METADATA_IN_CELL_TOOLTIPS',
  CHOOSE_COLUMNS_TO_EXPORT: 'CHOOSE_COLUMNS_TO_EXPORT',
  ENABLE_ENTITY_RELATIONSHIPS: 'ENABLE_ENTITY_RELATIONSHIPS',
  ENABLE_FIX_FOR_SS_34362_FORMULA_ADD_MISSING_COLUMNS:
    'ENABLE_FIX_FOR_SS_34362_FORMULA_ADD_MISSING_COLUMNS',
  ENABLE_FIX_FOR_SS_35380_DOUBLE_FETCHING_TARGETS:
    'ENABLE_FIX_FOR_SS_35380_DOUBLE_FETCHING_TARGETS',
  ENABLE_KANBAN: 'ENABLE_KANBAN',
  ENABLE_FIX_FOR_SS_35391_LR_TABS_DISAPPEAR: 'ENABLE_FIX_FOR_SS_35391_LR_TABS_DISAPPEAR',
  ENABLE_FIX_FOR_SS_35553_PLOTS_LOG_SCALE: 'ENABLE_FIX_FOR_SS_35553_PLOTS_LOG_SCALE',
  ENABLE_FIX_FOR_SS_35571_RETRY_WEBSOCKETS: 'ENABLE_FIX_FOR_SS_35571_RETRY_WEBSOCKETS',
  KANBAN_MAX_ROWS: 'KANBAN_MAX_ROWS',
  ENABLE_FIX_FOR_SS_36242_DEBOUNCE_KANBAN_RESULTS:
    'ENABLE_FIX_FOR_SS_36242_DEBOUNCE_KANBAN_RESULTS',
  ENABLE_FIX_FOR_SS_36448_AND_SS_36451_DUPLICATE_LIVE_REPORT:
    'ENABLE_FIX_FOR_SS_36448_AND_SS_36451_DUPLICATE_LIVE_REPORT',
  ENABLE_FIX_FOR_SS_36546_MPO_EDIT_FIX: 'ENABLE_FIX_FOR_SS_36546_MPO_EDIT_FIX',
  ENABLE_SKETCHER_ERROR_NOTIFICATIONS: 'ENABLE_SKETCHER_ERROR_NOTIFICATIONS',
  REALTIME_PROPERTY_REFRESH_DELAY: 'REALTIME_PROPERTY_REFRESH_DELAY',
  MAX_SCATTER_PLOT_VALUES: 'MAX_SCATTER_PLOT_VALUES',
  MAX_HISTOGRAM_PLOT_VALUES: 'MAX_HISTOGRAM_PLOT_VALUES',
  MAX_RADAR_PLOT_VALUES: 'MAX_RADAR_PLOT_VALUES',
  MAX_BOX_PLOT_VALUES: 'MAX_BOX_PLOT_VALUES',
  MAX_PIE_PLOT_VALUES: 'MAX_PIE_PLOT_VALUES',
  MAX_HEATMAP_PLOT_VALUES: 'MAX_HEATMAP_PLOT_VALUES',
  MAX_LINE_PLOT_VALUES: 'MAX_LINE_PLOT_VALUES',
  ENABLE_SAR_COLUMN_FILTERS: 'ENABLE_SAR_COLUMN_FILTERS',
  SPECIFY_COMPOUND_MATCHING_TYPE_SS_34107: 'SPECIFY_COMPOUND_MATCHING_TYPE_SS_34107',
  SHOW_STEREO_ANNOTATIONS_ALL_COLUMNS: 'SHOW_STEREO_ANNOTATIONS_ALL_COLUMNS',
  ENABLE_FIX_FOR_SS_38768_AVOID_FETCHING_ALL_ROW_RESULTS_IN_CROSS_SECTIONS:
    'ENABLE_FIX_FOR_SS_38768_AVOID_FETCHING_ALL_ROW_RESULTS_IN_CROSS_SECTIONS',
  ASSAY_VIEWER_TOOL_NAME: 'ASSAY_VIEWER_TOOL_NAME',
  ENABLE_TOOL_LINK_IN_AGGREGATE_TOOLTIP: 'ENABLE_TOOL_LINK_IN_AGGREGATE_TOOLTIP',
  ENABLE_SINGLE_COMPOUND_COPY_AS_MOL_V3000: 'ENABLE_SINGLE_COMPOUND_COPY_AS_MOL_V3000',
  VALID_GENERIC_ENTITY_ID_REGEX: 'VALID_GENERIC_ENTITY_ID_REGEX',
  ENABLE_FIX_FOR_SS_39454_ASSAY_DATE_PLOTS: 'ENABLE_FIX_FOR_SS_39454_ASSAY_DATE_PLOTS',
  USE_AUTHN_SERVICE_FOR_USER_LD_LOGIN: 'USE_AUTHN_SERVICE_FOR_USER_LD_LOGIN',
  PREFER_HIGH_PERFORMANCE_GPU_FOR_WEBPYMOL: 'PREFER_HIGH_PERFORMANCE_GPU_FOR_WEBPYMOL',
  ENABLE_MATRIX_WIDGET: 'ENABLE_MATRIX_WIDGET',
  ENABLE_LANDING_PAGES: 'ENABLE_LANDING_PAGES',
  RESET_PASSWORD_LINK: 'RESET_PASSWORD_LINK',
  ALLOW_PARTIAL_SUCCESS_ID_IMPORT: 'ALLOW_PARTIAL_SUCCESS_ID_IMPORT',
  COLUMN_PAGINATION_BUFFER_SIZE: 'COLUMN_PAGINATION_BUFFER_SIZE',
  COLUMN_PAGINATION_BUCKET_SIZE: 'COLUMN_PAGINATION_BUCKET_SIZE',
  ENABLE_MULTIPLE_INSTANCE_CUSTOM_TOOLS: 'ENABLE_MULTIPLE_INSTANCE_CUSTOM_TOOLS',
  ENABLE_FIX_FOR_SS_39645_GRID_FIND_INCORRECT_CELLS:
    'ENABLE_FIX_FOR_SS_39645_GRID_FIND_INCORRECT_CELLS',
  ENABLE_BIOLOGICS: 'ENABLE_BIOLOGICS',
  ENABLE_FFC_AUDIT_TRAIL_TOOLTIP_FE: 'ENABLE_FFC_AUDIT_TRAIL_TOOLTIP_FE',
  AUTHN_ACCESS_TOKEN_DURATION: 'AUTHN_ACCESS_TOKEN_DURATION',
  UNAGGREGATE_ALL_POSE_LEVEL_MODEL_RESULTS: 'UNAGGREGATE_ALL_POSE_LEVEL_MODEL_RESULTS',
  ENABLE_PUBLISHING_LIMITED_ASSAY_COLUMNS: 'ENABLE_PUBLISHING_LIMITED_ASSAY_COLUMNS',
  AUTHN_SAML_ENABLED: 'AUTHN_SAML_ENABLED',
  ENABLE_SAML: 'ENABLE_SAML',
  ENABLE_STRUCTURE_HIERARCHY: 'ENABLE_STRUCTURE_HIERARCHY',
  ENABLE_SEND_TO_MAESTRO_LINK: 'ENABLE_SEND_TO_MAESTRO_LINK',
  ALLOW_ALL_FILE_TYPES_FOR_BIO_IMPORT: 'ALLOW_ALL_FILE_TYPES_FOR_BIO_IMPORT',
  WARN_TOO_MANY_LIVEREPORT_CELLS: 'WARN_TOO_MANY_LIVEREPORT_CELLS',
  ENABLE_FIX_FOR_SS_47732_SANITIZE_LAYOUT_CONTENT:
    'ENABLE_FIX_FOR_SS_47732_SANITIZE_LAYOUT_CONTENT',
};

environment.logger = Logger.getLogger('bb.util.environment');

/**
 *  Helper method for getting an environment configuration
 *
 *  IMPORTANT: The return value is always a string or null.
 *
 *  @param {environment.SettingKey} key
 *  @return {?string}
 *  @private
 */
environment.getSettingValue = function (key) {
  const setting = goog.array.find(
    goog.global.environmentConfig || [],
    (config) => config.key === key,
  );
  return setting ? setting.value : null;
};

/**
 * Get the string value of the setting. If not found will return empty string.
 *
 * @param {environment.SettingKey} key
 * @return {string}
 */
environment.asString = function (key) {
  return environment.getSettingValue(key) || '';
};

/**
 * Returns an integer from the setting value.
 * Returns NaN if the value can't be parsed.
 *
 * @param {environment.SettingKey} key
 * @return {number}
 */
environment.asInt = function (key) {
  return parseInt(environment.getSettingValue(key), 10);
};

/**
 * Returns true if the settings value is equal to 'true'
 *
 * @param {environment.SettingKey} key
 * @return {boolean}
 * @export
 */
environment.asBoolean = function (key) {
  const value = environment.getSettingValue(key);
  if (!goog.isString(value)) {
    return false;
  }
  return _.trim(value.toLowerCase()) === 'true';
};

/**
 * Returns an object from the found setting.
 *
 * If the value is a string is must be a JSON stringified object
 *
 * @param {environment.SettingKey} key
 * @return {!Object}
 */
environment.asObject = function (key) {
  let value = environment.getSettingValue(key);
  if (goog.string.isEmptySafe(value)) {
    return {};
  }

  try {
    value = /** @type {!Object} */ (JSON.parse(value.toString()));
  } catch (e) {
    environment.logger.severe('Error parsing settings ("' + key + '")" as object!');
    value = null;
  }

  return value || {};
};

/**
 * Returns an array from the found setting, split on given delimiter
 * Defaults to CSV
 *
 * @param {environment.SettingKey} key
 * @param {string=} delimiter
 * @return {!Array<string>}
 */
environment.asArray = function (key, delimiter) {
  delimiter = delimiter || ',';
  const value = environment.getSettingValue(key);
  return value ? value.split(delimiter).map((item) => item.trim()) : [];
};

/**
 * Returns a map of all FFs in boolean form
 *
 * @returns {Object<environment.SettingKey, boolean>} featureFlags
 */
environment.flags = _.memoize(() => {
  return _.mapValues(environment.SettingKey, environment.asBoolean);
});

/** returns map of FFs in integer form
 *
 * @returns {Object<environment.SettingKey, number>}
 */
environment.integers = _.memoize(() => {
  return _.mapValues(environment.SettingKey, (key) => {
    const value = environment.asInt(key);
    return _.isNaN(value) ? undefined : value;
  });
});
export default environment;
